import React from 'react'
import { useFormContext } from 'react-hook-form'
import BoatStep1 from './BoatStep1'
import EngineStep1 from './EngineStep1'

const boatSaleTypeOptions = [['Bote', 'boat'], ['Bote con motor', 'boat_and_engine'], ['Motor', 'engine']]
const boatSaleTypeIcons = [require('../../../../../images/wizard/boat.svg'), require('../../../../../images/home/vehicle_types/6.svg'), require('../../../../../images/wizard/boat-engine.svg')]

const NauticalStep1 = ({ ad, vehicleType }) => {
  const { register, watch } = useFormContext()

  const selectedSaleType = watch('boat_sale_type')

  return (
    <div className='space-y-16 md:space-y-10'>
      <div className='grid my-10 grid-cols-1 gap-4 md:gap-10 md:w-2/3 mx-auto md:my-0 md:grid-cols-3'>
        {boatSaleTypeOptions.map((saleType, i) => {
          return (
            <label key={saleType[1]} className={`rounded text-center bg-white shadow py-4 flex md:py-0 md:bg-transparent md:shadow-none md:flex-col ${selectedSaleType && selectedSaleType !== saleType[1] && 'filter grayscale'} md:filter none`}>
              <img alt='Dunu506' src={boatSaleTypeIcons[i]} className='mx-6 md:mx-auto' />
              <div className='my-auto'>
                <input type='radio' {...register('boat_sale_type', { required: true })} value={saleType[1]} className='invisible absolute md:relative md:visible' />
                <span className='ml-2'>{saleType[0]}</span>
              </div>
            </label>
          )
        })}
      </div>

      {selectedSaleType && (selectedSaleType === 'boat' || selectedSaleType === 'boat_and_engine') && (
        <BoatStep1
          ad={ad}
          vehicleType={vehicleType}
          saleType={selectedSaleType}
        />
      )}

      {selectedSaleType && (selectedSaleType === 'engine' || selectedSaleType === 'boat_and_engine') && (
        <EngineStep1
          ad={ad}
          vehicleType={vehicleType}
          saleType={selectedSaleType}
        />
      )}
    </div>
  )
}

export default NauticalStep1
