import classNames from 'classnames'
import React, { useState } from 'react'

import InlineFileUploader from '../InlineFileUploader'
import Schedules from '../Schedules'

import branchImg from '../../images/custom_shops/branch.svg'
import checkImg from '../../images/custom_shops/check.svg'
import chevronDownImg from '../../images/custom_shops/chevron-down.svg'
import chevronUpImg from '../../images/custom_shops/chevron-up.svg'
import emailImg from '../../images/custom_shops/email.svg'
import phoneImg from '../../images/custom_shops/phone.svg'
import websiteImg from '../../images/custom_shops/website.svg'
import whatsappImg from '../../images/custom_shops/whatsapp.svg'

const CustomShopHeader = ({ customShop, inlineFileUploader, user }) => {
  const [panel, setPanel] = useState()
  const [branchMenuIsOpen, setBranchMenuIsOpen] = useState(false)
  const [selectedBranch, setSelectedBranch] = useState(customShop.branches[0])

  const panels = [
    { key: 'about', title: 'Sobre nosotros' },
    { key: 'services', title: 'Servicios' },
    { key: 'contact', title: 'Contacto' }
  ]

  const hasContactInfo = customShop.website || customShop.email || customShop.branches.length > 0 || customShop.schedules.length > 0
  const hasServicesInfo = customShop.allVehiclesWarranty || customShop.selectedVehiclesWarranty || customShop.receives || customShop.financing || customShop.buys || customShop.consignment || customShop.additionalBenefits
  const hasAboutUsInfo = customShop.aboutUs !== null && customShop.aboutUs !== ''

  const websiteUrl = customShop.website.startsWith('http') ? customShop.website : 'http://' + customShop.website

  const handleBranchSelected = branch => e => {
    setSelectedBranch(branch)
    setBranchMenuIsOpen(false)
  }

  const handleClick = panelName => e => {
    setPanel(panelName === panel ? undefined : panelName)
  }

  return (
    <>
      <div className='container absolute top-0 left-0 right-0 mt-14 md:mx-auto md:mt-36'>
        <div className='flex flex-wrap items-center mx-4 mt-3 md:m-auto md:items-end'>
          <div className='relative'>
            {user && user.id === customShop.user.id && (
              <div className='absolute flex w-full h-full'>
                <div className='m-auto'>
                  <InlineFileUploader {...inlineFileUploader} />
                </div>
              </div>
            )}
            <a href={`/${customShop.slug}`}>
              <div className='w-24 h-24 bg-center bg-no-repeat bg-cover rounded-full md:w-44 md:h-44' style={{ backgroundImage: `url(${customShop.logo?.url || 'https://picsum.photos/230'})` }} />
            </a>
          </div>
          <h1 className='flex-1 ml-2 text-xl font-bold text-white md:ml-6 md:mb-10 font-raleway md:text-3xl'>{customShop.name}</h1>
          <div className='flex w-full mx-auto mt-3 md:mb-11 md:flex-1'>
            {panels.map(item => (
              <button key={item.key} className={classNames('md:text-xl text-white truncate flex-grow md:flex-grow-0 md:ml-24 2xl:ml-36', { 'font-bold': panel === item.key })} onClick={handleClick(item.key)}>
                {item.title}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className='container mx-auto'>
        {panel === 'contact' && (
          <div className='px-0 pb-4 mx-auto text-gray-700 bg-gray xl:w-3/4 md:py-4 md:px-24 md:py-11'>
            {hasContactInfo
              ? (
                <div className='flex-1'>
                  {customShop.branches.length > 1 && (
                    <>
                      <div className='absolute flex invisible md:mb-8 md:visible md:relative'>
                        {customShop.branches.map((branch, i) => {
                          return (
                            <button
                              key={branch.id}
                              className={classNames('flex-shrink px-6 text-center text-blue-600 border-blue-600', { 'border-b-4 font-bold': selectedBranch === branch, 'border-b': selectedBranch !== branch })}
                              onClick={() => setSelectedBranch(branch)}
                            >
                              {i === 0 ? 'Sucursal Principal' : `Sucursal ${i + 1}`}
                            </button>
                          )
                        })}
                        <div className='flex-1 border-b border-blue-600' />
                      </div>
                      <div className='relative visible md:invisible md:absolute'>
                        <button className='flex-1 w-full py-2 text-lg font-bold text-blue-600 border-b border-blue-600' onClick={() => setBranchMenuIsOpen(!branchMenuIsOpen)}>
                          <div className='items-center ml-5 space-x-2 w-max'>
                            <span>{selectedBranch.name || 'Sucursal Principal'}</span>
                            <img alt='Dunu506' src={branchMenuIsOpen ? chevronUpImg : chevronDownImg} className='inline mb-1 ml-2' />
                          </div>
                        </button>
                        {branchMenuIsOpen && (
                          <div className='z-10 w-full text-center text-gray-700 divide-y shadow bg-gray-50 divide-solid'>
                            {customShop.branches.map((branch, i) => {
                              branch.name = i === 0 ? 'Sucursal Principal' : `Sucursal ${i + 1}`
                              return (
                                <button
                                  key={branch.id}
                                  className={`w-full text-lg py-4 ${selectedBranch === branch ? 'text-blue-600 font-bold' : 'text-gray-700'}`}
                                  onClick={handleBranchSelected(branch)}
                                >
                                  {branch.name}
                                </button>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {selectedBranch && (
                    <div className='grid grid-cols-1 gap-4 mx-4 my-5 md:grid-cols-2 md:m-auto md:gap-5'>
                      <div>
                        <img alt='Dunu506' src={branchImg} className='inline mb-1 mr-2' />
                        <span>{selectedBranch.address}, {selectedBranch.provinceOptions.find(option => option[1] === selectedBranch.province)[0]}</span>
                      </div>
                      {selectedBranch.phones.map((phone, key) => (
                        <div key={key}>
                          <img alt='Dunu506' src={phone.hasWhatsapp ? whatsappImg : phoneImg} className='inline mb-1 mr-2' />
                          <span>{(phone.phone && phone.phone) || '-'}</span>
                          <span>{(phone.extension && ` | Ext. ${phone.extension}`)}</span>
                        </div>
                      ))}
                      <div>
                        <img alt='Dunu506' src={websiteImg} className='inline mb-1 mr-2' />
                        {(customShop.website && <a href={websiteUrl} className='text-blue-600 underline' target='_blank' rel='noreferrer'>{websiteUrl.split('//')[1]}</a>) ||
                          <span>{`${window.location.hostname}/${customShop.slug}`}</span>}
                      </div>
                      <div>
                        <img alt='Dunu506' src={emailImg} className='inline mb-1 mr-2' />
                        <span>{customShop.email || customShop.user.email}</span>
                      </div>
                      <div>
                        <Schedules slug={customShop.slug} />
                      </div>
                    </div>
                  )}
                </div>
                )
              : <p className='py-5'>No hay información de contacto aún</p>}
          </div>
        )}
        {panel === 'services' && (
          <div className='px-5 text-gray-700 bg-gray py-9 md:px-24 md:py-11'>
            {hasServicesInfo
              ? (
                <div className='grid grid-cols-1 gap-4 mx-auto xl:w-3/4 md:grid-cols-2'>
                  {customShop.allVehiclesWarranty && (
                    <div>
                      <img alt='Dunu506' src={checkImg} className='inline mb-1 mr-2' />
                      <span>Garantía en todos los vehículos</span>
                    </div>
                  )}
                  {customShop.selectedVehiclesWarranty && (
                    <div>
                      <img alt='Dunu506' src={checkImg} className='inline mb-1 mr-2' />
                      <span>Garantía en vehículos selectos</span>
                    </div>
                  )}
                  {customShop.receives && (
                    <div>
                      <img alt='Dunu506' src={checkImg} className='inline mb-1 mr-2' />
                      <span>Recibimos vehículo como parte de pago</span>
                    </div>
                  )}
                  {customShop.financing && (
                    <div>
                      <img alt='Dunu506' src={checkImg} className='inline mb-1 mr-2' />
                      <span>Ofrecemos financiamiento</span>
                    </div>
                  )}
                  {customShop.buys && (
                    <div>
                      <img alt='Dunu506' src={checkImg} className='inline mb-1 mr-2' />
                      <span>Compramos su vehículo usado</span>
                    </div>
                  )}
                  {customShop.consignment && (
                    <div>
                      <img alt='Dunu506' src={checkImg} className='inline mb-1 mr-2' />
                      <span>Venda su vehículo con nosotros (consigna)</span>
                    </div>
                  )}
                  {customShop.additionalBenefits && customShop.additionalBenefits.split('\n').map((benefit, i) => {
                    return (
                      <div key={i}>
                        <img alt='Dunu506' src={checkImg} className='inline mb-1 mr-2' />
                        <span>{benefit}</span>
                      </div>
                    )
                  })}
                </div>
                )
              : <p className='py-5'>No hay información de servicios aún</p>}
          </div>
        )}
        {panel === 'about' && (
          <div className='p-4 mx-auto space-y-2 text-gray-700 bg-gray xl:w-3/4 pb-9 md:px-24 md:py-11 md:space-y-5'>
            <h3 className='font-bold md:text-xl'>Sobre {customShop.name}</h3>
            {hasAboutUsInfo
              ? <p className='whitespace-pre-wrap'>{customShop.aboutUs}</p>
              : <p>No hay información sobre {customShop.name} aún</p>}
          </div>
        )}
      </div>
    </>
  )
}

export default CustomShopHeader
