import React, { useState } from 'react'
import classNames from 'classnames'

import chevronDown from '../../images/chevron-down.svg'
import chevronUp from '../../images/chevron-up.svg'

const Section = props => {
  const [showList, setShowList] = useState(false)

  return (
    <>
      <div className={classNames('block space-y-6', { 'border-b border-gray-400': !showList })}>
        <button type='button' className='flex w-full items-center' onClick={() => setShowList(!showList)}>
          <div className='w-full font-bold text-left text-gray-700'>{props.label}</div>
          {showList && <img alt='Dunu506' src={chevronUp} />}
          {!showList && <img alt='Dunu506' src={chevronDown} />}
        </button>
        {showList && props.content}
      </div>
    </>
  )
}

export default Section
