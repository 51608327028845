import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import Buttons from '../../shared/Buttons'
import Title from '../../shared/Title'

const VehicleTypesWithHomePageUpsale = ['1', '4', '5']

const Step5 = ({ ad, nextStep, prevStep, setAd, upsalePrices, faqPath, url }) => {
  const methods = useForm({ defaultValues: ad })

  const [totalPrice, setTotalPrice] = useState(ad.upsales.map(upsale => upsalePrices[upsale]).reduce((a, b) => a + b, 0))

  const handleChange = (e) => {
    setTotalPrice(totalPrice + (e.target.checked ? 1 : -1) * upsalePrices[e.target.value])

    if (e.target.checked) {
      window.fbq('trackCustom', 'Upsale')

      window.gtag('event', 'conversion', { send_to: 'AW-306649816/OGECCOD2n4ADENi1nJIB' })
    }
  }

  const onSubmit = data => {
    setAd(Object.assign(ad, data))
    nextStep()
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Title text='Personalice su anuncio' />

        <div className='container pt-4 mx-auto text-gray-700 xl:w-3/4 md:pb-12 md:pt-6'>
          <p className='mx-8 md:text-center md:mx-0'>Puede añadir extras a su anuncio para que tenga prioridad en los resultados de búsqueda, se destaque visualmente o sea publicado en nuestras redes sociales, entre otros. Puede seleccionar todos los que quiera, cada uno tiene una duración de un mes.</p>

          <div className='px-5 pt-16 pb-12 md:pt-12'>
            <span className='float-left md:text-sm -mt-7 md:invisible md:absolute'>¡Gratis por tiempo limitado!</span>
            <div className='md:flex md:mx-auto'>
              <div className='flex-1 p-5 mb-1 bg-white border border-gray-400 rounded pb-7 md:pb-5 md:h-full'>
                <span className='text-xl font-bold font-raleway md:text-3xl'>Anuncio básico</span>
                <span className='absolute invisible ml-6 text-gray-400 md:visible md:relative'>(Requerido)</span>
                <p className='pt-1 md:pt-2'>Su anuncio se publicará durante un mes. Podrá renovarlo gratis ilimitadamente cuando lo necesite. <span className='text-gray-400 md:invisible md:absolute'>(Requerido)</span></p>
              </div>
              <div className='m-auto md:ml-8 w-max'>
                <p className='absolute invisible mb-1 md:visible md:relative'>¡Gratis por tiempo limitado!</p>
                <div>
                  <input type='radio' checked />
                  <span className='ml-2 text-lg text-blue-600 line-through'>2 usd</span>
                  <span className='ml-2 text-lg text-blue-600'>0 usd</span>
                </div>
              </div>
            </div>
          </div>

          <div className={`grid grid-cols-1 px-5 gap-14 md:gap-7 md:gap-5 md:${VehicleTypesWithHomePageUpsale.includes(ad.vehicle_type_id) ? 'grid-cols-4' : 'grid-cols-3 md:w-3/4'} md:mx-auto`}>
            <div>
              <span className='float-left text-blue-600 md:text-sm -mt-7'>Recomendado para su anuncio</span>
              <div className='p-5 bg-white border-2 border-blue-600 rounded pb-7 md:pb-5 md:h-full'>
                <p className='text-xl font-bold font-raleway md:text-xl md:text-3xl'>Destacar y Promover</p>
                <p className='pt-1 md:pt-2'>Su anuncio se destacará con un borde azul y tendrá prioridad en los resultados de búsqueda sobre los no destacados.</p>
                <p className='pt-1 md:pt-2'>Adicionalmente, su vehículo se promoverá como anuncio en redes sociales por 5 días (pauta $1 al día).</p>
              </div>
              <div className='h-0 pt-1 m-auto text-lg md:pt-2 w-max'>
                <input type='checkbox' {...methods.register('upsales[]')} onChange={handleChange} value='highlight' />
                <span className='ml-2 text-lg text-blue-600'>10 usd</span>
              </div>
            </div>
            <div>
              <div className='relative'>
                <img alt='Dunu506' className='absolute right-0 mr-2' src={require('../../../images/ribbon.svg')} />
              </div>
              <div className='p-5 bg-white border border-gray-400 rounded pb-7 md:pb-5 md:h-full'>
                <p className='pr-6 text-xl font-bold font-raleway md:text-3xl'>Añadir lazo</p>
                <p className='pt-1 md:pt-2 pr-'>Se añade un resaltador visual a su anuncio en forma de un pequeño lazo. Ayuda a llamar la atención de los usuarios pero no modifica su posición en los resultados de búsqueda.</p>
              </div>
              <div className='h-0 pt-1 m-auto text-lg md:pt-2 w-max'>
                <input type='checkbox' {...methods.register('upsales[]')} onChange={handleChange} value='ribbon' />
                <span className='ml-2 text-lg text-blue-600'>2 usd</span>
              </div>
            </div>
            <div>
              <div className='p-5 bg-white border border-gray-400 rounded pb-7 md:pb-5 md:h-full'>
                <p className='text-xl font-bold font-raleway md:text-3xl'>Publicar en Redes Sociales</p>
                <p className='pt-1 md:pt-2'>Su anuncio se publicará (una vez) en las cuentas oficiales de Dunu 506 en Instagram y Facebook.</p>
              </div>
              <div className='h-0 pt-1 m-auto text-lg md:pt-2 w-max'>
                <input type='checkbox' {...methods.register('upsales[]')} onChange={handleChange} value='social_media' />
                <span className='ml-2 text-lg text-blue-600'>5 usd</span>
              </div>
            </div>
            {VehicleTypesWithHomePageUpsale.includes(ad.vehicle_type_id) && (
              <div>
                <div className='p-5 bg-white border border-gray-400 rounded pb-7 md:pb-5 md:h-full'>
                  <p className='text-xl font-bold font-raleway md:text-3xl'>Publicar en Home Page</p>
                  <p className='pt-1 md:pt-2'>Su anuncio se publicará durante un mes en nuestra página principal. Los anuncios de página principal rotarán de forma equiparada para que tengan la misma visibilidad. Más información en <a href={faqPath} className='underline'>Preguntas Frecuentes.</a></p>
                </div>
                <div className='h-0 pt-1 m-auto text-lg md:pt-2 w-max'>
                  <input type='checkbox' {...methods.register('upsales[]')} onChange={handleChange} value='homepage' />
                  <span className='ml-2 text-lg text-blue-600'>15 usd</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <Buttons
          prevStep={prevStep}
          totalPrice={totalPrice}
        />
      </form>
    </FormProvider>
  )
}

export default Step5
