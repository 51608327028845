import React from 'react'
import { useFormContext } from 'react-hook-form'

import Title from '../../../../shared/Title'

const EngineStep2 = ({ ad }) => {
  const { register, formState: { errors } } = useFormContext()

  return (
    <>
      <Title text='Ficha técnica del motor' />
      <div className='container grid grid-cols-1 gap-8 px-8 py-4 mx-auto md:w-3/4 md:p-12 md:grid-cols-3 md:gap-10'>
        <div>
          <div className='mb-1'>Año (opcional)</div>
          <select {...register('engine_year')} className='block w-full rounded'>
            <option />
            {[...Array((new Date()).getFullYear() - 1948).keys()].map(x => (new Date()).getFullYear() + 1 - x).map(year => {
              return <option key={year} value={year}>{year}</option>
            })}
          </select>
        </div>

        <div>
          <div className='mb-1'>Horas motor (opcional)</div>
          <input type='number' {...register('engine_hours', { min: { value: 0, message: 'Debe ser superior a 0' } })} className='block w-full rounded' />
          {errors.engine_hours && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.engine_hours.message}</p>}
        </div>

        <div>
          <div className='mb-1'>Caballos de fuerza (opcional)</div>
          <input type='number' {...register('engine_hp', { min: { value: 0, message: 'Debe ser superior a 0' } })} className='block w-full rounded' />
          {errors.engine_hp && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.engine_hp.message}</p>}
        </div>

        <div>
          <div className='mb-4'>Tipo de combustible</div>
          <div className='flex'>
            <div className='flex-1'>
              <img alt='Dunu506' src={require('../../../../../images/wizard/gas-station.svg')} />
            </div>
            <div className='flex-1 space-y-1'>
              {ad.fuel_type_options.map(option => {
                return (
                  <label key={option[1]} className='block'>
                    <input type='radio' {...register('fuel_type', { required: 'Es obligatorio' })} value={option[1]} />
                    <span className='ml-2'>{option[0]}</span>
                  </label>
                )
              })}
            </div>
          </div>
          {errors.fuel_type && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.fuel_type.message}</p>}
        </div>
      </div>
    </>
  )
}

export default EngineStep2
