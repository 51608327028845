import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import React, { useRef, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Buttons from '../../shared/Buttons'
import Title from '../../shared/Title'

const Step3 = ({ ad, amount, exchangeRate, formAuthenticityToken, prevStep, user, userData }) => {
  const { handleSubmit, register, setValue, watch } = useForm()

  const onSubmit = data => {
    setDisabled(true)

    formRef.current.submit()
  }

  const formRef = useRef(null)
  const [paypalError, setPaypalError] = useState(false)
  const [paypalLoading, setPaypalLoading] = useState(false)
  const [paypalSuccessful, setPaypalSuccessful] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const paymentMethod = watch('check_request[payment_method]')

  useEffect(() => {
    setDisabled(paymentMethod === null || paymentMethod === 'paypal')
  }, [paymentMethod])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const createOrder = (data, actions) => {
    return actions.order.create({
      application_context: {
        shipping_preference: 'NO_SHIPPING'
      },
      payer: {
        address: {
          address_line_1: 'Dirección',
          address_line_2: 'San José',
          admin_area_1: 'Cantón',
          admin_area_2: 'Distrito',
          country_code: 'CR',
          postal_code: '10107'
        },
        email_address: user.email,
        name: {
          given_name: user.fullName?.split(' ').slice(-1).join(' ') || 'Juan',
          surname: user.fullName?.split(' ').slice(0, -1).join(' ') || 'Perez'
        },
        phone: {
          phone_number: {
            national_number: user.phone?.replace(/\+| |-/gi, '') || '50688888888'
          }
        }
      },
      purchase_units: [{
        amount: { value: amount }
      }]
    })
  }

  const handleApprove = (data, actions) => {
    setPaypalLoading(true)
    return actions.order.capture().then(details => {
      setValue('check_request[paypal_transaction_id]', details.purchase_units[0].payments.captures[0].id)
      setPaypalLoading(false)
      setPaypalSuccessful(true)
      setDisabled(false)
    })
  }

  const handleError = error => {
    if (error) {
      setPaypalError(true)
    }
  }

  return (

    <PayPalScriptProvider options={{ 'client-id': document.querySelector('meta[name="paypal-client-id"]').getAttribute('content') }}>
      <form action={`/ads/${ad.id}/check_requests`} encType='multipart/form-data' method='post' ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <input type='hidden' name='authenticity_token' value={formAuthenticityToken} />
        <input type='hidden' name='check_request[payment_method]' value={paymentMethod} />
        <input type='hidden' name='user[full_name]' value={userData.fullName} />
        <input type='hidden' name='user[has_whatsapp]' value={userData.hasWhatsapp} />
        <input type='hidden' name='user[phone]' value={userData.phone} />

        <Title text='Pago' />
        <div className='container px-8 pt-4 mx-auto md:w-3/4 md:p-12'>
          {!paymentMethod && !paypalSuccessful && (
            <div id='buttons' className='grid items-end grid-cols-1 gap-2 my-4 md:my-10 md:grid-cols-3 md:gap-0'>
              <label className='py-10 text-center bg-white rounded shadow md:py-0 md:bg-transparent md:shadow-none md:filter none'>
                <img src='https://www.paypalobjects.com/webstatic/mktg/logo/AM_mc_vs_dc_ae.jpg' border='0' alt='PayPal Acceptance Mark' />
                <input type='radio' {...register('check_request[payment_method]', { required: true })} value='paypal' className='absolute invisible md:relative md:visible' />
                <span className='md:ml-2'>PayPal / Tarjeta Débito - Crédito</span>
              </label>
              <label className='py-10 text-center bg-white rounded shadow md:py-0 md:bg-transparent md:shadow-none md:filter none'>
                <img src={require('../../../images/paymethods/sinpe.webp')} width='200' height='200' className='mx-auto' alt='SINPE Móvil' />
                <input type='radio' {...register('check_request[payment_method]', { required: true })} value='sinpe' className='absolute invisible md:relative md:visible' />
                <span className='md:ml-2'>Sinpe Movil</span>
              </label>
              <label className='py-10 text-center bg-white rounded shadow md:py-0 md:bg-transparent md:shadow-none md:filter none'>
                <img src={require('../../../images/paymethods/banco.webp')} width='150' height='150' top='200%' className='mx-auto' alt='Dunu506' />
                <input type='radio' {...register('check_request[payment_method]', { required: true })} value='transfer' className='absolute invisible md:relative md:visible' />
                <span className='md:ml-2'>Transferencia Bancaria</span>
              </label>
            </div>
          )}

          {paymentMethod === 'transfer' && (
            <div className='mx-auto space-y-4 md:w-1/2'>
              <div className='text-center'>
                <p><strong>ORDEN DE COMPRA RECIBIDA</strong></p>
              </div>
              <p>Se ha seleccionado "Transferencia Bancaria" como método de pago.</p>
              <p>El detalle de pago debe incluir el "ID de anuncio".</p>
              <ul className='ml-8 list-disc'>
                <li><strong>ID de anuncio:</strong> <b style={{ color: '#2563eb' }}>{ad.id}</b></li>
              </ul>
              <p>El total a pagar es de ${amount} (tipo de cambio, ₡{exchangeRate}) = <b style={{ color: '#2563eb' }}>₡{Intl.NumberFormat('en').format(amount * exchangeRate)}</b></p>
              <ul className='ml-8 list-disc'>
                <li><strong>Nombre:</strong> METAL DUNES BUSINESS SOCIEDAD ANONIMA</li>
                <li><strong>Cédula Jurídica:</strong> 3-101-465700</li>
                <li><strong>Banco: BAC Costa Rica</strong></li>
                <ul className='ml-4 list-disc'>
                  <li>(₡) Número de cuenta cliente (₡): 947164307</li>
                  <li>(₡) Número de cuenta IBAN (₡): CR89010200009471643072</li>
                  <li>($) Número de cuenta cliente ($): 947164299</li>
                  <li>($) Número de cuenta IBAN ($): CR45010200009471642991</li>
                </ul>
              </ul>
              <p><strong>OPCIONAL:</strong> Si gusta factura electrónica favor enviar su nombre completo y número de cédula a <a href='mailto:soporte@dunu506.com' target='_blank' rel='noreferrer'>soporte@dunu506.com</a>.</p>
              <p>Favor de tocar el botón de <strong>Finalizar</strong> para completar el proceso.</p>
            </div>
          )}

          {paymentMethod === 'sinpe' && (

            <div className='mx-auto space-y-4 md:w-1/2'>
              <div className='text-center'>
                <p><strong>ORDEN DE COMPRA RECIBIDA</strong></p>
              </div>
              <p>Se ha seleccionado "SINPE Movil" como método de pago.</p>
              <p>El detalle de pago debe incluir el "ID de anuncio".</p>
              <ul className='ml-8 list-disc'>
                <li><strong>ID de anuncio:</strong> <b style={{ color: '#2563eb' }}>{ad.id}</b></li>
              </ul>
              <p>El total a pagar es de ${amount} (tipo de cambio, ₡{exchangeRate}) = <b style={{ color: '#2563eb' }}>₡{Intl.NumberFormat('en').format(amount * exchangeRate)}</b></p>
              <ul className='ml-8 list-disc'>
                <li><strong>Número Móvil:</strong> 7217 - 8526</li>
                <li><strong>Nombre de Cuenta:</strong> Metal_dunes_business</li>
              </ul>
              <p><strong>OPCIONAL:</strong> Si gusta factura electrónica favor enviar su nombre completo y número de cédula a <a href='mailto:soporte@dunu506.com' target='_blank' rel='noreferrer'>soporte@dunu506.com</a>.</p>
              <p>Favor de tocar el botón de <strong>Finalizar</strong> para completar el proceso.</p>
            </div>
          )}

          {paymentMethod === 'paypal' && (
            <>
              <input type='hidden' {...register('check_request[paypal_transaction_id]', { required: true })} />

              <div className='mx-auto md:w-1/2'>
                {paypalError && (
                  <div className='text-center'>
                    Hubo un problema procesando el pago, por favor vuelve a intentarlo.
                  </div>
                )}

                {paypalSuccessful && (
                  <div className='text-center'>
                    El pago fue realizado con éxito.
                  </div>
                )}

                {!paypalSuccessful && (
                  <PayPalButtons
                    createOrder={createOrder}
                    disabled={paypalLoading}
                    onApprove={handleApprove}
                    onError={handleError}
                  />
                )}
              </div>
            </>
          )}

        </div>
        <Buttons
          prevStep={prevStep}
          disabled={disabled}
          finish
        />
      </form>
    </PayPalScriptProvider>
  )
}

export default Step3
