import React, { useState } from 'react'

import phoneImg from '../../images/custom_shops/phone.svg'
import whatsappImg from '../../images/custom_shops/whatsapp.svg'

const UserPhones = props => {
  const [isPhoneVisible, setIsPhoneVisible] = useState(false)

  return (
    <div className='space-y-7 md:space-y-4'>
      {isPhoneVisible
        ? props.phones.map((phone, index) => (
          <div key={index} class='flex'>
            <img alt='Dunu506' src={phone.has_whatsapp ? whatsappImg : phoneImg} className='inline mb-1 mr-2' />
            <a href={`tel:${phone.phone}`}>
              {phone.phone}
            </a>
            {phone.extension && (
              <span class='ml-1 text-gray-700'>| Ext. {phone.extension}</span>
            )}
          </div>
          ))
        : props.phones && (
          <div className='flex'>
            <button onClick={() => setIsPhoneVisible(true)} className='block w-84 px-4 py-2 text-blue-600 border border-blue-600 rounded'>
              <img alt='Dunu506' src={phoneImg} className='inline -mt-1 mr-2' />
              <span className='hidden md:inline'>Ver número de teléfono</span>
              <span className='md:hidden'>Llamar al vendedor por teléfono</span>
            </button>
          </div>
        )}
    </div>
  )
}

export default UserPhones
