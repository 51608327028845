import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import CreatableSelect from 'react-select/creatable'

import Title from '../../../shared/Title'

const BicyclesStep2 = ({ ad, models }) => {
  const { control, formState: { errors }, register, setValue } = useFormContext()

  const frameSizeLabel = (name) => {
    switch (name) {
      case 'XXS':
        return '47cm - 48cm | 11" - 12" | XXS'
      case 'XS':
        return '49cm - 50cm | 13" - 14" | XS'
      case 'S':
        return '51cm - 53cm | 15" - 16" | S'
      case 'M':
        return '54cm - 55cm | 17" - 18" | M'
      case 'L':
        return '56cm - 58cm | 19" - 20" | L'
      case 'XL':
        return '59cm - 60cm | 21" - 22" | XL'
      case 'XXL':
        return '61cm - 63cm | 22" - 24" | XXL'
    }
  }

  return (
    <>
      <Title text='Ficha técnica de su bicicleta' />
      <div className='container md:w-3/4 mx-auto py-4 px-8 md:p-12 grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-10'>
        <div>
          <div className='mb-1'>Tamaño</div>
          <select {...register('frame_size', { required: 'Es obligatorio' })} className='block w-full rounded'>
            <option />
            {ad.frame_size_options.map(option => {
              return <option key={option[1]} value={option[1]}>{frameSizeLabel(option[0])}</option>
            })}
          </select>
          {errors.frame_size && <p className='text-red-500 italic text-sm mt-1 md:mb-2'>{errors.frame_size.message}</p>}
        </div>

        <div className='md:col-span-2 md:w-1/2 md:pr-5'>
          <div className='mb-1'>Tamaño de llanta</div>
          <Controller
            name='wheel_size'
            control={control}
            rules={{ required: 'Es obligatorio' }}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                className='block w-full rounded'
                options={models.wheelSizes.map(ws => { return { label: ws.name, value: ws.id } })}
                placeholder='Seleccionar...'
                noOptionsMessage={() => 'No hay opciones'}
                formatCreateLabel={(value) => `Crear "${value}"`}
                onCreateOption={s => {
                  setValue('wheel_size_other', s)
                  setValue('wheel_size', { value: '', label: s })
                }}
              />
            )}
          />
          <p className='text-gray-500 mt-1'>Puede agregar nuevos tamaños</p>
          {errors.wheel_size && <p className='text-red-500 italic text-sm mt-1 md:mb-2'>{errors.wheel_size.message}</p>}
        </div>

        <div>
          <div className='mb-4'>Género (opcional)</div>
          <div className='flex'>
            <div className='flex-1'>
              <img alt='Dunu506' src={require('../../../../images/wizard/bicycle.svg')} />
            </div>
            <div className='flex-1 space-y-1'>
              <label className='block'>
                <input type='radio' {...register('frame_gender')} value='' />
                <span className='ml-2'>N/A</span>
              </label>
              {ad.frame_gender_options.map(option => {
                return (
                  <label key={option[1]} className='block'>
                    <input type='radio' {...register('frame_gender')} value={option[1]} />
                    <span className='ml-2'>{option[0]}</span>
                  </label>
                )
              })}
            </div>
          </div>
        </div>

        <div>
          <div className='mb-4'>Material del marco</div>
          <div className='flex'>
            <div className='flex-1'>
              <img alt='Dunu506' src={require('../../../../images/wizard/bicycle-frame.svg')} />
            </div>
            <div className='flex-1 space-y-1'>
              {models.frameMaterials.map(frameMaterial => {
                return (
                  <label key={frameMaterial.id} className='block'>
                    <input type='radio' {...register('frame_material_id', { required: 'Es obligatorio' })} value={frameMaterial.id} />
                    <span className='ml-2'>{frameMaterial.name}</span>
                  </label>
                )
              })}
            </div>
          </div>
          {errors.frame_material_id && <p className='text-red-500 italic text-sm mt-1 md:mb-2'>{errors.frame_material_id.message}</p>}
        </div>

        <div>
          <div className='mb-4'>Frenos (opcional)</div>
          <div className='flex'>
            <div className='flex-1'>
              <img alt='Dunu506' src={require('../../../../images/wizard/brake.svg')} />
            </div>
            <div className='flex-1 space-y-1'>
              <label className='block'>
                <input type='radio' {...register('bicycle_brake_id')} value='' />
                <span className='ml-2'>N/A</span>
              </label>
              {models.bicycleBrakes.map(bicycleBrakes => {
                return (
                  <label key={bicycleBrakes.id} className='block'>
                    <input type='radio' {...register('bicycle_brake_id')} value={bicycleBrakes.id} />
                    <span className='ml-2'>{bicycleBrakes.name}</span>
                  </label>
                )
              })}
            </div>
          </div>
        </div>

        <div>
          <div className='mb-4'>Tipo de suspensión</div>
          <div className='flex'>
            <div className='flex-1'>
              <img alt='Dunu506' src={require('../../../../images/wizard/fork.svg')} />
            </div>
            <div className='flex-1 space-y-1'>
              {ad.suspension_type_options.map(option => {
                return (
                  <label key={option[1]} className='block'>
                    <input type='radio' {...register('suspension_type', { required: 'Es obligatorio' })} value={option[1]} />
                    <span className='ml-2'>{option[0]}</span>
                  </label>
                )
              })}
            </div>
          </div>
          {errors.suspension_type && <p className='text-red-500 italic text-sm mt-1 md:mb-2'>{errors.suspension_type.message}</p>}
        </div>

        <div>
          <div className='mb-4'>Recorrido de suspensión promedio (opcional)</div>
          <div className='flex'>
            <div className='flex-1'>
              <img alt='Dunu506' src={require('../../../../images/wizard/shock.svg')} />
            </div>
            <div className='flex-1 space-y-1'>
              <label className='block'>
                <input type='radio' {...register('suspension_travel')} value='' />
                <span className='ml-2'>N/A</span>
              </label>
              {ad.suspension_travel_options.map(option => {
                return (
                  <label key={option[1]} className='block'>
                    <input type='radio' {...register('suspension_travel')} value={option[1]} />
                    <span className='ml-2'>{option[0]}</span>
                  </label>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BicyclesStep2
