import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import Title from '../../../shared/Title'

const numberOfDoorsOptions = [['2/3', 2], ['4/5', 4]]

const CarsStep2 = ({ ad }) => {
  const { control, formState: { errors }, register, setValue, watch } = useFormContext()

  const watchPendingRegistration = watch('pending_registration')

  return (
    <>
      <Title text='Ficha técnica de su auto' />
      <div className='container grid grid-cols-1 gap-8 px-8 py-4 mx-auto md:w-3/4 md:p-12 md:grid-cols-3 md:gap-10'>
        <div>
          <div className='mb-1'>Año</div>
          <select {...register('year', { required: 'Es obligatorio' })} className='block w-full rounded'>
            <option />
            {[...Array((new Date()).getFullYear() - 1948).keys()].map(x => (new Date()).getFullYear() + 1 - x).map(year => {
              return <option key={year} value={year}>{year}</option>
            })}
          </select>
          {errors.year && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.year.message}</p>}
        </div>

        <div>
          <div className='flex gap-5'>
            <div className='flex-1'>
              <div className='mb-1'>Kilometraje (opcional)</div>
              <Controller
                name='m'
                control={control}
                defaultValue={ad.mileage}
                render={({ field }) => (
                  <NumberFormat
                    {...field}
                    allowNegative={false}
                    className='block w-full rounded'
                    onValueChange={v => setValue('mileage', v.value)}
                    thousandSeparator=','
                  />
                )}
              />
            </div>
            <div>
              <div className='mb-1'>Unidad</div>
              <select {...register('mileage_unit')} className='block rounded'>
                <option key={1} value='km'>Kilómetros</option>
                <option key={2} value='mi'>Millas</option>
              </select>
            </div>
          </div>
        </div>

        <div>
          <div className='mb-1'>Último dígito de placa (opcional)</div>
          <Controller
            name='license_plate_last_digit'
            control={control}
            render={({ field }) => (
              <NumberFormat
                {...field}
                disabled={watchPendingRegistration}
                className='block w-full rounded'
                format='#'
              />
            )}
          />
          {errors.license_plate_last_digit && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.license_plate_last_digit.message}</p>}

          <label className='block mt-2'>
            <input type='checkbox' {...register('pending_registration')} />
            <span className='ml-2'>Vehículo pendiente de inscripción</span>
          </label>
        </div>

        <div>
          <div className='mb-4'>Tipo de transmisión</div>
          <div className='flex'>
            <div className='flex-1'>
              <img alt='Dunu506' src={require('../../../../images/wizard/gearshift.svg')} />
            </div>
            <div className='flex-1 space-y-1'>
              {ad.transmission_type_options.map(option => {
                return (
                  <label key={option[1]} className='block'>
                    <input type='radio' {...register('transmission_type', { required: 'Es obligatorio' })} value={option[1]} />
                    <span className='ml-2'>{option[0]}</span>
                  </label>
                )
              })}
            </div>
          </div>
          {errors.transmission_type && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.transmission_type.message}</p>}
        </div>

        <div>
          <div className='mb-4'>Tipo de combustible</div>
          <div className='flex'>
            <div className='flex-1'>
              <img alt='Dunu506' src={require('../../../../images/wizard/gas-station.svg')} />
            </div>
            <div className='flex-1 space-y-1'>
              {ad.fuel_type_options.map(option => {
                return (
                  <label key={option[1]} className='block'>
                    <input type='radio' {...register('fuel_type', { required: 'Es obligatorio' })} value={option[1]} />
                    <span className='ml-2'>{option[0]}</span>
                  </label>
                )
              })}
            </div>
          </div>
          {errors.fuel_type && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.fuel_type.message}</p>}
        </div>

        <div>
          <div className='mb-1'>Cilindraje (cc, opcional)</div>
          <Controller
            name='engine_type_other'
            control={control}
            defaultValue={ad.engine_type_other}
            rules={{ min: { value: 1, message: 'Debe ser superior a 0' } }}
            render={({ field }) => (
              <NumberFormat
                {...field}
                allowNegative={false}
                decimalSeparator={false}
                className='block w-full rounded'
                onValueChange={v => setValue('engine_type_other', v.value)}
              />
            )}
          />
          {errors.engine_type_other && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.engine_type_other.message}</p>}
        </div>

        <div>
          <div className='mb-4'>Cantidad de puertas</div>
          <div className='flex'>
            <div className='flex-1'>
              <img alt='Dunu506' src={require('../../../../images/wizard/car.svg')} />
            </div>
            <div className='flex-1 space-y-1'>
              {numberOfDoorsOptions.map(option => {
                return (
                  <label key={option[1]} className='block'>
                    <input type='radio' {...register('number_of_doors', { required: 'Es obligatorio' })} value={option[1]} />
                    <span className='ml-2'>{option[0]}</span>
                  </label>
                )
              })}
            </div>
          </div>
          {errors.number_of_doors && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.number_of_doors.message}</p>}
        </div>

        <div className='md:col-span-2 md:w-1/2 md:pr-5'>
          <div className='mb-4'>Tipo de tracción</div>
          <div className='flex'>
            <div className='flex-1'>
              <img alt='Dunu506' src={require('../../../../images/wizard/wheel.svg')} />
            </div>
            <div className='flex-1 space-y-1'>
              {ad.drive_type_options.map(option => {
                return (
                  <label key={option[1]} className='block'>
                    <input type='radio' {...register('drive_type', { required: 'Es obligatorio' })} value={option[1]} />
                    <span className='ml-2'>{option[0]}</span>
                  </label>
                )
              })}
            </div>
          </div>
          {errors.drive_type && <p className='mt-1 text-sm italic text-red-500 md:mb-2'>{errors.drive_type.message}</p>}
        </div>

        <div className='space-y-1'>
          <div>Color de exterior (opcional)</div>
          {ad.external_color_options.map(option => {
            return (
              <label key={option[1]} className='flex items-center'>
                <input type='radio' {...register('external_color')} value={option[1]} />
                <img alt='Dunu506' className='ml-2' src={require(`../../../../images/colors/${option[1]}.svg`)} />
                <span className='ml-2'>{option[0]}</span>
              </label>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default CarsStep2
