import React, { useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'

import Buttons from '../../../shared/Buttons'
import CarsStep1 from './CarsStep1'
import GenericStep1 from './GenericStep1'
import NauticalStep1 from './NauticalStep1/index'
import Title from '../../../shared/Title'

const CARS = 1
const MOTORBIKES = 2
const BICYCLES = 3
const INDUSTRIAL = 4
const ATVS = 5
const NAUTICAL = 6

const Step1 = ({ ad, models, nextStep, setAd, setFinishPath, setNewId, id }) => {
  const methods = useForm({ defaultValues: ad })

  const watchVehicleTypeId = methods.watch('vehicle_type_id')

  const selectedVehicleType = models.vehicleTypes.find(vehicleType => vehicleType.id === parseInt(watchVehicleTypeId))

  const cleanFields = () => {
    methods.setValue('engine_make', null)
    methods.setValue('engine_make_other', null)
    methods.setValue('engine_type', null)
    methods.setValue('engine_type_other', null)
    methods.setValue('make', null)
    methods.setValue('make_other', null)
    methods.setValue('model', null)
    methods.setValue('model_other', null)
    methods.setValue('vehicle_subtype', null)
    methods.setValue('vehicle_subtype_other', null)
  }

  const onSubmit = (data) => {
    setAd(Object.assign(ad, data))

    const jsonData = {
      boat_sale_type: ad.boat_sale_type,
      engine_make_id: ad.engine_make?.value,
      engine_make_other: ad.engine_make_other,
      engine_type_id: ad.engine_type?.value,
      make_id: ad.make?.value,
      make_other: ad.make_other,
      model_id: ad.model?.value,
      model_other: ad.model_other,
      vehicle_subtype_id: ad.vehicle_subtype?.value,
      vehicle_subtype_other: ad.vehicle_subtype_other,
      vehicle_type_id: ad.vehicle_type_id
    }

    const url = id ? `/api/v1/publications/${id}` : '/api/v1/publications'
    window.fetch(url, {
      method: id ? 'PUT' : 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      },
      body: JSON.stringify(jsonData)
    })
      .then(response => response.json())
      .then(d => {
        if (!id) {
          setFinishPath(d.url)
          setNewId(d.id)
        }
        nextStep()
      })
  }

  const handleClick = () => {
    if (window.outerHeight <= document.getElementById('selects').getBoundingClientRect().bottom) {
      setTimeout(function () {
        window.scrollTo({
          top: document.getElementById('buttons').getBoundingClientRect().bottom - 48,
          behavior: 'smooth'
        })
      }, 1)
    }
  }

  useEffect(() => {
    if (!ad.id) { return }

    if (ad.make_id && !ad.make) {
      window.fetch(`/api/v1/makes/${ad.make_id}`)
        .then(response => response.json())
        .then(data => {
          methods.setValue('make', { label: data.name, value: data.id })
        })
    } else if (!ad.make) {
      methods.setValue('make', { label: ad.make_other, value: '' })
    }

    if (ad.model_id && !ad.model) {
      window.fetch(`/api/v1/models/${ad.model_id}`)
        .then(response => response.json())
        .then(data => {
          methods.setValue('model', { label: data.name, value: data.id })
        })
    } else if (!ad.model) {
      methods.setValue('model', { label: ad.model_other, value: '' })
    }

    if (ad.vehicle_subtype_id && !ad.vehicle_subtype) {
      window.fetch(`/api/v1/vehicle_subtypes/${ad.vehicle_subtype_id}`)
        .then(response => response.json())
        .then(data => {
          methods.setValue('vehicle_subtype', { label: data.name, value: data.id })
        })
    }

    if (ad.engine_make_id && !ad.engine_make) {
      window.fetch(`/api/v1/makes/${ad.engine_make_id}`)
        .then(response => response.json())
        .then(data => {
          methods.setValue('engine_make', { label: data.name, value: data.id })
        })
    } else if (!ad.engine_make) {
      methods.setValue('engine_make', { label: ad.make_other, value: '' })
    }

    if (ad.engine_type_id && !ad.engine_type) {
      window.fetch(`/api/v1/engine_types/${ad.engine_type_id}`)
        .then(response => response.json())
        .then(data => {
          methods.setValue('engine_type', { label: data.name, value: data.id })
        })
    }
  }, [])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={`mb-16 md:mb-${selectedVehicleType ? '32' : '72'}`}>
        <Title text='¿Qué tipo de vehículo desea vender?' />

        <div className='container w-full px-6 mx-auto md:w-3/4 md:p-12'>
          <div id='buttons' className='grid grid-cols-2 gap-2 my-4 md:my-10 md:grid-cols-6 md:gap-0'>
            {models.vehicleTypes.map(vehicleType => {
              return (
                <label key={vehicleType.id} onClick={handleClick} className={`rounded text-center py-10 bg-white shadow md:py-0 md:bg-transparent md:shadow-none ${selectedVehicleType && selectedVehicleType !== vehicleType && 'filter grayscale'} md:filter none`}>
                  <img alt='Dunu506' src={require(`../../../../images/home/vehicle_types/${vehicleType.id}.svg`)} className='mx-auto' />
                  <input type='radio' {...methods.register('vehicle_type_id', { required: true })} onClick={cleanFields} value={vehicleType.id} className='absolute invisible md:relative md:visible' />
                  <span className='md:ml-2'>{vehicleType.name}</span>
                </label>
              )
            })}
          </div>

          <div id='selects' className='my-12 md:my-0'>
            {selectedVehicleType && selectedVehicleType.id === CARS && (
              <CarsStep1 ad={ad} vehicleType={selectedVehicleType} />
            )}
            {selectedVehicleType && [ATVS, BICYCLES, INDUSTRIAL, MOTORBIKES].includes(selectedVehicleType.id) && (
              <GenericStep1 ad={ad} vehicleType={selectedVehicleType} />
            )}
            {selectedVehicleType && selectedVehicleType.id === NAUTICAL && (
              <NauticalStep1 ad={ad} vehicleType={selectedVehicleType} />
            )}
            {selectedVehicleType && (
              <p className='mt-8 text-gray-500'>* Se pueden crear Marcas y Modelos nuevos. Simplemente escriba, y si no existe, seleccione "Crear".</p>
            )}
          </div>
        </div>

        {selectedVehicleType && (
          <Buttons />
        )}
      </form>
    </FormProvider>
  )
}

export default Step1
