import React, { useRef } from 'react'

import pencil from '../../images/custom_shops/pencil-alt.svg'

const InlineFileUploader = ({ action, formAuthenticityToken, name }) => {
  const fileRef = useRef()
  const formRef = useRef()

  const handleChange = (e) => {
    formRef.current.submit()
  }

  const handleClick = (e) => {
    e.preventDefault()
    fileRef.current.click()
  }

  return (
    <form action={action} method='post' encType='multipart/form-data' ref={formRef}>
      <input type='hidden' name='_method' value='patch' />
      <input type='hidden' name='authenticity_token' value={formAuthenticityToken} />
      <input type='file' name={name} ref={fileRef} onChange={handleChange} className='hidden' />

      <button className='p-2 md:bg-white md:bg-opacity-25 rounded text-white' onClick={handleClick}>
        <img alt='Dunu506' src={pencil} className='opacity-75 mx-1 mb-1 inline md:opacity-100' />
        <span className='invisible absolute h-0 overflow-hidden md:visible md:relative md:h-auto'>Cambiar foto</span>
      </button>
    </form>
  )
}

export default InlineFileUploader
