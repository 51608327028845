import React from 'react'

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

const ImageViewer = props => {
  return (
    <CarouselProvider
      naturalSlideWidth={24}
      naturalSlideHeight={24}
      totalSlides={props.images.length}
    >
      <div className='relative'>
        <Slider className='w-full bg-gray-200 rounded-md aspect-w-16 aspect-h-16 md:aspect-h-9'>
          {props.images.map((url, index) => (
            <Slide index={index} key={index}>
              <div className='w-full bg-center bg-no-repeat bg-contain aspect-w-16 aspect-h-16 md:aspect-h-9' style={{ backgroundImage: `url("${url}")` }} />
            </Slide>
          ))}
        </Slider>
        <ButtonBack className='absolute p-6 -inset-y-0'>
          <img alt='Dunu506' src={require('../../images/chevron-left.svg')} />
        </ButtonBack>
        <ButtonNext className='absolute top-0 bottom-0 right-0 p-6'>
          <img alt='Dunu506' src={require('../../images/chevron-right.svg')} />
        </ButtonNext>
      </div>
      <div className='w-full overflow-x-auto'>
        <div className='flex justify-center m-auto mt-3 space-x-2 w-max flex-nowrap'>
          {props.images.map((url, index) => (
            <Dot slide={index} key={index} className='w-20 h-20 my-1 bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url("${url}")` }} />
          ))}
        </div>
      </div>
    </CarouselProvider>
  )
}

export default ImageViewer
