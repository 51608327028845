import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import chevronDown from '../../images/chevron-down.svg'
import chevronUp from '../../images/chevron-up.svg'
import schedule from '../../images/schedule.svg'

const Schedules = props => {
  const [unfolded, setUnfolded] = useState(false)
  const [schedules, setSchedules] = useState([])
  const fixed = !!props.fixed

  const weekDays = [['Lunes', 'monday'], ['Martes', 'tuesday'], ['Miércoles', 'wednesday'], ['Jueves', 'thursday'], ['Viernes', 'friday'], ['Sábado', 'saturday'], ['Domingo', 'sunday']]

  useEffect(() => {
    window.fetch(`/api/v1/custom_shops/${props.slug}`)
      .then(response => response.json())
      .then(data => {
        const newSchedules = []
        weekDays.forEach(option => {
          let day = ''
          data.schedules.filter(daySchedule => daySchedule.day === option[1]).forEach((schedule, index) => {
            day += index > 0 ? ' y de ' : 'De '
            day += `${(new Date(schedule.fromTime)).toLocaleTimeString('es-CR', { timeZone: 'UTC', timeStyle: 'short' })} a ${(new Date(schedule.toTime)).toLocaleTimeString('es-CR', { timeZone: 'UTC', timeStyle: 'short' })}`
          })
          newSchedules.push(day === '' ? 'Cerrado' : day)
        })
        setSchedules(newSchedules)
      })
  }, [])

  const handleClick = () => {
    if (fixed) return

    setUnfolded(!unfolded)
  }

  return (
    <>
      <button type='button' className='flex items-center w-full text-gray-700' onClick={handleClick}>
        <img alt='Dunu506' className='inline color-blue-600' src={schedule} />
        <span className={classNames('ml-2 text-left', { 'w-full': unfolded, 'mr-2': !unfolded })}>{`Hoy: ${schedules[new Date().getDay() - 1]}`}</span>
        {!fixed && unfolded && <img alt='Dunu506' src={chevronUp} />}
        {!fixed && !unfolded && <img alt='Dunu506' src={chevronDown} />}
      </button>
      {unfolded && (
        <div className='m-2 space-y-2 text-gray-700'>
          {weekDays.map((option, index) => (
            <div key={index} className={`flex ${schedules[index] === 'Cerrado' ? 'text-gray-400' : 'text-gray-700'}`}>
              <div className='w-20 md:w-24'>{option[0]}:</div>
              <div className='flex-1'>{schedules[index]}</div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default Schedules
